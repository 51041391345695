import React from 'react'
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout'

import { GatsbyImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'

import SEO from '../components/seo'
import Utils from '../components/utils'

const Tag = ({ data, location, pageContext }) => {
  return (
    <Layout>

      <SEO
        pagetitle={`${pageContext.tagname}の名言`}
        pagedesc={`${pageContext.tagname}の名言`}
        pagepath={location.pathname}
      />

      <div className="l-wrap l-tag">
        <h2 className="m-heading">{pageContext.tagdesc}</h2>
        {/* <h3 className="m-heading--sub">{pageContext.tagdesc}</h3> */}
        <div className='m-quotes'>
          {data.allContentfulBlogPost.edges.map(( { node }) => {
            const num = Math.floor( Math.random() * Utils.color.length )
            return (
              <article className={`m-quotes__item ${Utils.color[num]}`} key={node.id}>
                <Link to={`/${node.category.slug}/${node.slug}/`}>
                  <h3 className="m-quotes__hdg">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                    <span className="m-quotes__txt">{node.title}</span>
                  </h3>
                </Link>

                <dl className="m-quotes__details">
                  <Link to={`/person/${node.category.slug}/`}>
                    <dt className="m-quotes__img">
                      <figure>
                        <GatsbyImage
                          image={node.category.selfie.gatsbyImageData}
                          description={node.category.selfie.description}
                        />
                      </figure>
                    </dt>
                  </Link>
                  <Link to={`/person/${node.category.slug}/`}>
                    <dd className="m-quotes__name">{node.category.name}</dd>
                  </Link>
                </dl>
              </article>
            )
          })}
        </div>
      </div>
    </Layout>

  )
}


export const query = graphql`
  query($slug: String!) {
    allContentfulBlogPost(
      sort: {fields: contentful_id}
      filter: {badges: {elemMatch: {slug: {eq: $slug}}}}
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
          category {
            slug
            name
            selfie {
              gatsbyImageData(layout: FULL_WIDTH)
              description
            }
          }
        }
      }
    }
  }
`


export default Tag;